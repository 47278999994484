<template>
  <div>
    <template v-if="!amount_selected">

      <div class="l-form__item h5  sp-mb-2">{{ $t("on_boarding.amount_of_shareholders.title") }}</div>
      <p>{{ $t("on_boarding.amount_of_shareholders.intro") }}</p>

      <div class="sp-mt-3  sp-mb-4">
        <label class="form-label" for="shareholders">{{ $t('form_fields.shareholder_label') }}</label>

        <div style="max-width: 240px;">
          <d-select id="shareholders" v-model="amountOfShareholders">
            <template v-for="(option, index) in shareholdersList">
              <option :value="option.value" :key="index">
                {{ option.text }}
              </option>
            </template>
          </d-select>
        </div>
      </div>

      <div v-if="hasError('others')" class="sp-mtb-2">
        <d-form-invalid-feedback  :force-show="true">
          <span v-html="getError('others')"></span>
        </d-form-invalid-feedback>
      </div>

      <div class="u-divide">
        <router-link v-if="!firstTime" :to="{name: 'onBoarding.initial', params: {providerId: providerId}}" class="btn  btn-light">
          {{ $t('form_fields.to_overview') }}
        </router-link>
        <div v-else></div>

        <loading v-if="isLoading" />
        <d-button v-else @click="createUboStatement">
          {{ $t('form_fields.save_continue') }}
        </d-button>
      </div>

    </template>
    <template v-else-if="shareholder_fields_mounted">
      <div class="h4  sp-mb-2">
        {{ $t("on_boarding.shareholder_fields.title") }}
        <small v-if="amountOfShareholders >= 2" class="u-color-secondary--500" style="font-weight: bold; float: right; font-size: 15px;"><span class="u-color-primary--400">{{ shareholderId }}</span> / {{ amountOfShareholders}}</small>
      </div>

      <hr />

      <div class="l-form  sp-mt-2  sp-mb-4">

        <div class="l-form__item  h5  sp-mb-0">{{ $t("on_boarding.shareholder_fields.personal_info") }}</div>

        <div class="l-form__item">

          <div class="form-label">{{ $t('form_fields.id_card') }}</div>
          <div class="font-explanation  sp-mt-1  sp-mb-2">
            <p>{{ $t('on_boarding.shareholder_fields.id_card_explanation') }}</p>
          </div>

          <div class="custom-file  form__file-uploader">
            <input type="file" class="custom-file-input" id="id_card"
                   @change="previewCardIdFront"
                   accept="image/png, image/jpeg, image/gif, application/pdf">
            <label class="custom-file-label" for="id_card">
              <span v-html="fileLabel('cardIdFront')"></span>
            </label>
          </div>

          <d-form-invalid-feedback v-if="hasError('cardIdFront')" :force-show="true">
            <span v-html="getError('cardIdFront')"></span>
          </d-form-invalid-feedback>
        </div>

        <div class="l-form__item">
          <div class="form-label">{{ $t('form_fields.id_card_back') }}</div>
          <div class="custom-file  form__file-uploader">
            <input type="file" class="custom-file-input" id="id_card_back"
                   @change="previewCardIdBack"
                   accept="image/png, image/jpeg, image/gif, application/pdf">
            <label class="custom-file-label" for="id_card_back">
              <span v-html="fileLabel('cardIdBack')"></span>
            </label>
          </div>

          <d-form-invalid-feedback v-if="hasError('cardIdBack')" :force-show="true">
            <span v-html="getError('cardIdBack')"></span>
          </d-form-invalid-feedback>
        </div>

      </div>

      <div class="l-form  sp-mt-2  sp-mb-4">

        <div class="l-form__item">
          <label class="form-label" for="idNumber">{{ $t('form_fields.id_number') }}</label>
          <d-input id="idNumber" v-model="idNumber" :class="{ 'is-invalid': hasError('idNumber') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('idNumber')" :force-show="true">
            <span v-html="getError('idNumber')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="title">{{ $t('form_fields.title.label') }}</label>
          <d-select id="title" v-model="title" :class="{ 'is-invalid': hasError('title') }">
            <template v-for="(option, index) in titles">
              <option :value="option.value" :key="index" :disabled="option.value == ''">
                {{ $t('form_fields.title.options.' + option.key ) }}
              </option>
            </template>
          </d-select>
          <d-form-invalid-feedback v-if="hasError('title')" :force-show="true">
            <span v-html="getError('title')"></span>
          </d-form-invalid-feedback>
        </div>

        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="lastName">{{ $t('form_fields.lastname') }}</label>
          <d-input id="lastName" v-model="lastName" :class="{ 'is-invalid': hasError('lastName') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('lastName')" :force-show="true">
            <span v-html="getError('lastName')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item">
          <label class="form-label" for="firstName">{{ $t('on_boarding.shareholder_fields.firstnames') }}</label>
          <d-input id="firstName" v-model="firstName" :class="{ 'is-invalid': hasError('firstName') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('firstName')" :force-show="true">
            <span v-html="getError('firstName')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item" data-size="1/2">
          <label class="form-label">{{ $t('form_fields.date_of_birth') }}</label>
          <date-picker v-model="dateOfBirth"
                       :settings="{
                        maxDate: new Date().setFullYear((new Date().getFullYear() - 18))
                      }"
          ></date-picker>
          <d-form-invalid-feedback v-if="hasError('dateOfBirth')" :force-show="true">
            <span v-html="getError('dateOfBirth')"></span>
          </d-form-invalid-feedback>
        </div>

        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="placeOfBirth">{{ $t('form_fields.place_of_birth') }}</label>
          <d-input id="placeOfBirth" v-model="placeOfBirth" :class="{ 'is-invalid': hasError('placeOfBirth') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('placeOfBirth')" :force-show="true">
            <span v-html="getError('placeOfBirth')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="countryOfBirth">{{ $t('form_fields.country_of_birth') }}</label>
          <d-select id="countryOfBirth" v-model="countryOfBirth" :class="{ 'is-invalid': hasError('countryOfBirth') }">
            <option value="" disabled>{{ $t('form_fields.choose_a_country') }}</option>
            <template v-for="(item, index) in countries">
              <option :value="item.value" :key="index">
                {{ item.text }}
              </option>
            </template>
          </d-select>
          <d-form-invalid-feedback v-if="hasError('countryOfBirth')" :force-show="true">
            <span v-html="getError('countryOfBirth')"></span>
          </d-form-invalid-feedback>
        </div>

        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="nationality">{{ $t('form_fields.nationality') }}</label>
          <d-select id="nationality" v-model="nationality" :class="{ 'is-invalid': hasError('nationality') }">
            <option value="" disabled>{{ $t('form_fields.choose_a_country') }}</option>
            <template v-for="(item, index) in countries">
              <option :value="item.value" :key="index">
                {{ item.text }}
              </option>
            </template>
          </d-select>
          <d-form-invalid-feedback v-if="hasError('nationality')" :force-show="true">
            <span v-html="getError('nationality')"></span>
          </d-form-invalid-feedback>
        </div>

      </div>

      <hr />

      <div class="l-form  sp-mt-4  sp-mb-4">
        <div class="l-form__item  h5  sp-mb-0">{{ $t("on_boarding.shareholder_fields.address_info") }}</div>

        <div class="l-form__item">
          <label class="form-label" for="address">{{ $t('form_fields.address') }}</label>
          <d-input id="address" v-model="address" :class="{ 'is-invalid': hasError('address') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('address')" :force-show="true">
            <span v-html="getError('address')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="zipcode">{{ $t('form_fields.zipcode') }}</label>
          <d-input id="zipcode" v-model="zipcode" :class="{ 'is-invalid': hasError('zipcode') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('zipcode')" :force-show="true">
            <span v-html="getError('zipcode')"></span>
          </d-form-invalid-feedback>
        </div>

        <div class="l-form__item" data-size="1/2">
          <label class="form-label" for="city">{{ $t('form_fields.city') }}</label>
          <d-input id="city" v-model="city" :class="{ 'is-invalid': hasError('city') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('city')" :force-show="true">
            <span v-html="getError('city')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item">
          <label class="form-label" for="country">{{ $t('form_fields.country') }}</label>
          <d-select id="country" v-model="country" :class="{ 'is-invalid': hasError('country') }" :disabled="allDisabled">
            <option value="" disabled>{{ $t('form_fields.choose_a_country') }}</option>
            <template v-for="(item, index) in countries">
              <option :value="item.value" :key="index">
                {{ item.text }}
              </option>
            </template>
          </d-select>
          <d-form-invalid-feedback v-if="hasError('country')" :force-show="true">
            <span v-html="getError('country')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item  sp-pt-2">
          <div class="form-label">{{ $t('form_fields.proof_address.label') }}</div>

          <div class="font-explanation  sp-mt-1  sp-mb-2">
            <p>{{ $t('form_fields.proof_address.explanation.line_1') }}</p>
            <ul>
              <li>{{ $t('form_fields.proof_address.explanation.line_2') }}</li>
              <li>{{ $t('form_fields.proof_address.explanation.line_3') }}</li>
              <li>{{ $t('form_fields.proof_address.explanation.line_4') }}</li>
            </ul>
            <p>{{ $t('form_fields.proof_address.explanation.line_5') }}</p>
          </div>

          <div class="custom-file  form__file-uploader">
            <input type="file" class="custom-file-input" id="proofOfAddress"
                   @change="previewProofOfAddress"
                   accept="image/png, image/jpeg, image/gif, application/pdf">
            <label class="custom-file-label" for="proofOfAddress">
              <span v-html="fileLabel('proofOfAddress')"></span>
            </label>
          </div>

          <d-form-invalid-feedback v-if="hasError('proofOfAddress')" :force-show="true">
            <span v-html="getError('proofOfAddress')"></span>
          </d-form-invalid-feedback>
        </div>

      </div>

      <hr />

      <div class="l-form  sp-mt-4  sp-mb-4">
        <div class="l-form__item  h5  sp-mb-0">{{ $t("on_boarding.shareholder_fields.shares_info") }}</div>

        <div class="l-form__item">
          <label class="form-label" for="position">{{ $t('form_fields.position.label') }}</label>
          <d-select id="position" v-model="position" :class="{ 'is-invalid': hasError('position') }">
            <template v-for="(option, index) in positions">
              <option :value="option.value" :key="index" :disabled="option.value == ''">
                {{ $t('form_fields.position.options.' + option.key ) }}
              </option>
            </template>
          </d-select>
          <d-form-invalid-feedback v-if="hasError('position')" :force-show="true">
            <span v-html="getError('position')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item"  v-if="position === 'Other'">
          <label class="form-label" for="otherPosition">{{ $t('form_fields.position_other') }}</label>
          <d-input id="otherPosition" v-model="otherPosition" :class="{ 'is-invalid': hasError('otherPosition') }" :disabled="allDisabled" />
          <d-form-invalid-feedback v-if="hasError('otherPosition')" :force-show="true">
            <span v-html="getError('otherPosition')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item" :class="{ 'is-disabled': amountOfShareholders === 1}" >
          <label class="form-label  sp-mb-0" for="shares">{{ $t('form_fields.shares') }}</label>
          <div>
            {{ $t('form_fields.amount_of_shares') }}:
            <div style="display: inline-block; max-width: 100px;" class="sp-ml-2">
              <d-input id="shares" v-model="shares" type="number" :disabled="allDisabled" />
            </div>
            %
          </div>

          <d-slider v-if="!allDisabled" v-model="shares" :range="{ min: 25, max: 100 }" :options="{ step: 1 }"/>

          <d-form-invalid-feedback v-if="hasError('shares')" :force-show="true">
            <span v-html="getError('shares')"></span>
          </d-form-invalid-feedback>
        </div>


        <div class="l-form__item">
          <div>
            <d-checkbox inline v-model="decisionMaker">
              <span class="u-no-user-select">
                {{ $t('form_fields.decision_maker') }}
              </span>
            </d-checkbox>
          </div>

          <div>
            <d-checkbox inline v-model="politicalyExposed">
              <span class="u-no-user-select">
                {{ $t('form_fields.politically_exposed') }}
              </span>
            </d-checkbox>
          </div>
        </div>

        <div v-if="hasError('others')" class="l-form__item">
          <d-form-invalid-feedback  :force-show="true">
            <span v-html="getError('others')"></span>
          </d-form-invalid-feedback>
        </div>

      </div>

      <hr />

      <div class="u-divide">
        <router-link v-if="!firstTime" :to="{name: 'onBoarding.initial', params: {providerId: providerId}}" class="btn  btn-light">
          {{ $t('form_fields.to_overview') }}
        </router-link>
        <div v-else></div>

        <loading v-if="isLoading" />
        <d-button v-else @click="submit">
          {{ shareholderId === amountOfShareholders ? $t('form_fields.save_continue') : $t('on_boarding.shareholder_fields.next_shareholder') }}
        </d-button>
      </div>


    </template>
  </div>
</template>

<script>
import formSettings, {rules} from "~/onBoardingSettings"
import {mapRegistrationFields, mapUboFields} from "~/store";
import mixins from '~/mixins';
import DatePicker from "@/components/DatePicker";
import Vue from "vue";
import Loading from "@/components/loading";

export default {
  name: "ShareholderFields",
  components: {Loading, DatePicker},
  mixins: [mixins],

  props: {
    id: Number,
    providerId: {
      type: String,
      required: true,
    }
  },

  data: () => ({

    amount_selected: false,
    shareholder_fields_mounted: false,
    amountOfShareholders: 1,
    shareholderId: 1,
    errors: [],
    isLoading: false,

    shareholdersList: formSettings.shareholders,
    titles: formSettings.titles,
    positions: formSettings.positions,

    rules: {
      title: rules.title.rules,
      firstName: rules.stringCheck.rules,
      lastName: rules.stringCheck.rules,
      dateOfBirth: rules.date.rules,
      placeOfBirth: rules.stringCheck.rules,
      countryOfBirth: rules.country.rules,
      nationality: rules.country.rules,
      idNumber: rules.stringCheck.rules,

      address: rules.stringCheck.rules,
      zipcode: rules.stringCheck.rules,
      city: rules.stringCheck.rules,
      country: rules.country.rules,

      position: rules.position.rules,
      shares: rules.share.rules,

      cardIdFront: rules.file.rules,
      proofOfAddress: rules.file.rules,
    },

    countries: formSettings.country,
    allDisabled: false,
  }),

  computed: {
    ...mapUboFields([
      'formData.title',
      'formData.firstName',
      'formData.lastName',
      'formData.dateOfBirth',
      'formData.placeOfBirth',
      'formData.countryOfBirth',
      'formData.nationality',

      // Personal address
      'formData.address',
      'formData.city',
      'formData.zipcode',
      'formData.country',

      // Company information
      'formData.shares',
      'formData.position',
      'formData.otherPosition',
      'formData.decisionMaker',
      'formData.politicalyExposed',
      //
      'formData.idNumber',
      'formData.cardIdFront',
      'formData.cardIdBack',
      'formData.proofOfAddress',
      'formData',
    ]),
    ...mapRegistrationFields([
      'firstTime'
    ]),
    pickerLocale: function () {
      let customLocale = this.$store.state.providerStore.formData.locale
      return customLocale.replace("_", "-")
    }
  },

  methods: {

    hasError: function (field) {
      return this.errors.find(error => error.field === field);
    },

    getError: function (field) {
      return this.errors.find(error => error.field === field).messages.join('<br/>');
    },

    resetValues() {
      this.title = "";
      this.firstName = "";
      this.dateOfBirth = "";
      this.lastName = "";
      this.placeOfBirth = "";
      this.countryOfBirth = "";
      this.nationality = "";

      // Business address
      this.address = "";
      this.city = "";
      this.zipcode = "";
      this.country = "";

      // Company information
      this.shares = "0";
      this.position = "";
      this.decisionMaker = false;
      this.politicalyExposed = false;

      // ID information
      this.idNumber = "";
      this.cardIdFront = null;
      this.cardIdBack = null;
      this.proofOfAddress = null;

      const idCardUploader = document.getElementById("id_card");
      const idCardBackUploader = document.getElementById("id_card_back");
      const proofOfAddressUploader = document.getElementById("proofOfAddress");

      if(this.isset(idCardUploader)) idCardUploader.value = '';
      if(this.isset(idCardBackUploader)) idCardBackUploader.value = '';
      if(this.isset(proofOfAddressUploader)) proofOfAddressUploader.value = '';
    },

    previewCardIdFront (event) {
      if(event.target.files.length <= 0) return;
      this.cardIdFront = event.target.files[0];
    },

    previewCardIdBack (event) {
      if(event.target.files.length <= 0) return;
      this.cardIdBack = event.target.files[0];
    },

    previewProofOfAddress (event) {
      if(event.target.files.length <= 0) return;
      this.proofOfAddress = event.target.files[0];
    },

    fileLabel(attribute) {

      if(this.isset(this[attribute])) {
        return this[attribute].name + '<small>(' + this.number_format(this[attribute].size / 1000, 2, '.', '') + 'kB)</small>';
      }
      return this.$t('form_fields.search');

    },

    createUboStatement() {

      this.isLoading = true
      let variables = {
        input: {
          providerID: this.providerId,
          nbUbo: this.amountOfShareholders
        }
      }

      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/CreateUBOStatement.gql'), variables, fetchPolicy: 'no-cache'
      }).then(data => {

        new Promise((resolve, reject) => {
          if(data.data.createUBOStatement.success) resolve();
          else reject(data.data.createUBOStatement);
        }).then(() => {

          this.amount_selected = true
          this.shareholder_fields_mounted = true

          // If it's the only shareholder prefill the amount of shares
          if (this.amountOfShareholders === 1) this.shares = "100"

        }).catch((error) => {

          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });
        })
      }).catch((error) => {
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });
      }).then(() => {
        this.isLoading = false
      })

    },

    uploadUBO() {

      this.isLoading = true
      let variables = {
        input: {
          providerID: this.providerId,
          title: this.title,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          zipCode: this.zipcode,
          city: this.city,
          addressCountry: this.country,
          position: this.position,
          positionDetail: this.otherPosition,
          decisionMaker: (this.decisionMaker) ? 1 : 0,
          pep: (this.politicalyExposed) ? 1 : 0,

          idNumber: this.idNumber,
          idDocument: this.cardIdFront,
          idDocumentBack: this.cardIdBack,

          dateOfBirth: this.dateOfBirth,
          placeOfBirth: this.placeOfBirth,
          countryOfBirth: this.countryOfBirth,
          nationality: this.nationality,
          share: Number(this.shares),

          addressProofDocument: this.proofOfAddress,
        }
      }

      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/RegisterUBO.gql'), variables, fetchPolicy: 'no-cache'
      }).then(data => {

        new Promise((resolve, reject) => {
          if(data.data.registerUBO.success) resolve();
          else reject(data.data.registerUBO);
        })
        .then(() => {
          if (this.shareholderId < this.amountOfShareholders) this.nextShareholder()
          else this.nextStep()
        })
        .catch((error) => {

          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });
        })

      }).catch((error) => {
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });
      })
      .then(() => {
        this.isLoading = false
      })
    },

    uploadLegalRepresentativeUBO() {

      this.isLoading = true
      let variables = {
        input: {
          providerID: this.providerId,
          //position: this.position, // Todo: Check with HiPay is NOT available in API
          //positionDetail: this.otherPosition, // Todo: Check with HiPay is NOT available in API
          decisionMaker: (this.decisionMaker) ? 1 : 0,
          pep: (this.politicalyExposed) ? 1 : 0,

          idNumber: this.idNumber,
          idDocument: this.cardIdFront,
          idDocumentBack: this.cardIdBack,

          dateOfBirth: this.dateOfBirth,
          placeOfBirth: this.placeOfBirth,
          countryOfBirth: this.countryOfBirth,
          nationality: this.nationality,
          share: Number(this.shares),
          addressProofDocument: this.proofOfAddress,
        }
      }

      try {
        this.$apollo.mutate({
          mutation: require('~/graphql/onBoarding/LegalRepresentativeUBO.gql'), variables, fetchPolicy: 'no-cache'
        }).then(data => {
          const res = data.data.registerLegalRepresentativeUBO
          if (res.success) {

            if (this.shareholderId < this.amountOfShareholders) this.nextShareholder()
            else this.nextStep()

            this.isLoading = false

          } else {
            alert(res.errors[0].message);
            this.isLoading = false
          }
        })
      } catch (error) {
        alert(error)
        this.isLoading = false
      }
    },

    submit() {

      this.errors = [];
      let valid = true;

      for( let attribute in this.rules) {

        let rulesValid = this.runValidation(this[attribute], this.rules[attribute]);
        if(!rulesValid[0]) {
          valid = false;
          this.errors.push({
            field: attribute,
            messages: rulesValid[1]
          });
        }
      }

      // If valid form submit all data to the server
      if (!valid) {
        this.errors.push({
          field: 'others',
          messages: [this.$t('form_validation.contains_errors')]
        });
        return;
      }

      this.uploadUBO();
    },

    nextShareholder() {

      const self = this

      // Reset the store formData
      this.$store.dispatch("uboStore/addShareHolder", this.formData)

      this.shareholder_fields_mounted = false

      // Goto the next shareholder
      this.shareholderId++;
      this.resetValues();

      Vue.nextTick()
        .then(function () {
          self.shareholder_fields_mounted = true

          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }, 200)
        })
    },

    nextStep() {
      this.$router.push({name: 'onBoarding.step4', params: {providerId: this.providerId}})
    },

  }
};
</script>
